export function idValid(id) {
  return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(id)
}

export function telValid(tel) {
  return /^1[3456789]\d{9}$/.test(tel)
}

export function emailValid(email) {
  return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(email)
}

export function userNoValid(user) {
  return /^[a-zA-Z0-9_]{4,20}$/.test(user)
}

export function pwdValid(pwd) {
  // return /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,20}$/.test(pwd)
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])^.{8,20}$/.test(pwd)
}

export function getBirthFromIdCard(idCard) {
  if (idCard.length != 18 && idCard.length != 15) {
    return "请输入正确的身份证号码";
  }
  if (idCard.length == 18) {
    const year = idCard.substring(6).substring(0, 4);// 得到年份
    const month = idCard.substring(10).substring(0, 2);// 得到月份
    const day = idCard.substring(12).substring(0, 2);// 得到日
    return (year + "-" + month + "-" + day);
  }
  return null;
}

export function getSexFromIdCard(idCard) {
  let sex = 0;
  // 身份证号码为空
  if (idCard == "" || idCard.length <= 0) {
    sex = 0;
  }
  if (idCard.length == 18) {
    if ((idCard.substring(16).substring(0, 1) - 0) % 2 == 0) { // 判断性别
      sex = 2; // 女
    } else {
      sex = 1; // 男
    }
  }
  return sex;
}
