<template>
  <div class="page">
    <Header/>
    <div class="wrap">
      <div class="forgot">
        <h3>找回密码</h3>
        <div class="line"></div>
        <div class="form">
          <a-form
              id="formForgot"
              :form="form"
              ref="formForgot"
              @submit="handleSubmit"
            >
            <div class="container">
              <a-form-item label="报名号/身份证号">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入报名号/身份证号"
                  v-decorator="[
                    'username',
                    {
                      rules: [{ required: true, message: '请输入报名号/身份证号' }],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>

              <a-form-item label="手机号">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  :maxLength=11
                  placeholder="请输入手机号"
                  v-decorator="[
                    'phone',
                    {
                      rules: [{ required: true, message: '' },{ validator: validatePhone }],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>

              <a-form-item label="验证码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  placeholder="请输入验证码"
                  v-decorator="[
                    'code',
                    {
                      rules: [{ required: true, message: '请输入验证码' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-button type="link" class="codeBtn" slot="addonAfter" :disabled="codeDisabled" @click="sendCode">{{btnText}}</a-button>
                </a-input>
              </a-form-item>

              <a-form-item label="设置密码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="password"
                  autocomplete="false"
                  placeholder="请输入密码"
                  v-decorator="[
                    'password',
                    {
                      rules: [{ required: true, message: '' },{ validator: pwdValid}],
                      validateTrigger: 'blur',
                    },
                  ]"
                ></a-input>
              </a-form-item>

              <a-form-item label="确认密码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="password"
                  autocomplete="false"
                  placeholder="请再次输入密码"
                  v-decorator="[
                    'passwordTwo',
                    {
                      rules: [{ required: true, message: '' },{ validator: pwdEcValid }],
                      validateTrigger: 'blur',
                    },
                  ]"
                ></a-input>
              </a-form-item>
   
              <a-form-item>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  htmlType="submit"
                  class="login-button"
                  >确认找回</a-button
                >
              </a-form-item>

            </div>
          </a-form>
        </div>
      </div>
    </div>
    <Footer />
    
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { forgotPwd, forgotCode } from '@/api/api';
import encrypt from "@/utils/encrypt";
import { telValid, pwdValid } from '@/utils/reg.js'


export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      btnText: '获取验证码',
      codeDisabled: false,
      username: '',
      phone: '',
    };
  },
  methods: {
    // 验证
    pwdValid(rule, value, callback){
      if (!value){
         callback("请输入密码")
      }else if (!pwdValid(value)){
         callback("密码8-20位，必须包含大写字母、小写字母、数字")
      }
      callback()
    },
    pwdEcValid(rule, value, callback){
      let onePwd = this.form.getFieldValue("password")
      if (!value){
         callback("请再次输入密码")
      }else if (onePwd !== value){
         callback("两次输入的密码不一致")
      }
      callback()
    },
    validatePhone(rule, value, callback){
      if (!value){
         callback("请输入手机号")
      }else if (!telValid(value)){
         callback("请输入有效手机号")
      }
      callback()
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if(values.password){
          if (!pwdValid(values.password)) {
            this.$message.error({
              content: '密码8-20位，必须包含大写字母、小写字母、数字',
            });
            return;
          }
        }
        if(values.passwordTwo){
          if (values.passwordTwo != values.password) {
            this.$message.error('两次输入的密码不一致');
            return;
          }
        }
        if (!err) {
          forgotPwd({
            schoolCode: localStorage.getItem("schoolCode"),
            type: localStorage.getItem("stage"),
            phone: values.phone.trim(),
            code: values.code.trim(),
            username: values.username.trim(),
            password: encrypt(values.password.trim(),values.username.trim())
          }).then(res => {
            if (res.code == 0) {
              this.form.resetFields();
              this.$message.success(res.description)
              this.$router.replace({
                name: "Login",
              });
            } else {
              this.$message.error(res.description)
            }
          })
        }
      });
    },
    sendCode() {
      this.username = this.form.getFieldValue('username')
      this.phone = this.form.getFieldValue('phone')
      if (!this.username) {
        this.$message.error('请填写账号/身份证号');
        return;
      }
      if (!this.phone) {
        this.$message.error('请填写手机号');
        return;
      } else {
        if (!telValid(this.phone.trim())) {
          this.$message.error('请填写正确格式的手机号码');
          return;
        }
      }
      this.codeDisabled = true;
      forgotCode({
        schoolCode: window.localStorage.getItem("schoolCode"),
        type: window.localStorage.getItem("stage"),
        phone: this.phone.trim(),
        username: this.username.trim(),
      }).then(res => {
        if (res.code == 0) {
          this.$message.success(res.description);
          var time = 60    // 定义60秒的倒计时
          var codeV = setInterval(function () {
            this.btnText = `重新获取${--time}s`;
            if (time == -1) {  // 清除setInterval倒计时，按钮变回原样
              clearInterval(codeV);
              this.codeDisabled = false;
              this.btnText = '获取验证码';
            }
          }, 1000)
        } else {
          this.$message.error(res.description);
        }
      })
    },
  },
  mounted() {
    this.form.resetFields();
  },
};
</script>

<style lang="less" scoped>
.page {
  // background: url(~@/assets/user/bg.png) center bottom no-repeat;
  background: #f5f5f5;
  min-height: 100%;
  width: 100%;
  background-size: cover;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/ .ant-form-item-label > label::after {
  content: '';
}
/deep/ .ant-input-group-addon {
  background: transparent;
  border-left: 0;
}
.codeBtn{
  padding: 0 4px;
  // padding: 0 4px 0 14px;
  // border-left: 1px solid #ddd;
  // height: auto;
}
.wrap {
  width: 1000px;
  margin: 0 auto 40px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px 	rgba(0, 0, 0, 0.1);
}
.forgot {
  width: 372px;
  margin: 0 auto;
  padding: 32px 0 36px;
  h3{
    font-size: 20px;
    color: #333; 
    font-weight: 600;
    margin-bottom: 6px;
  }
  .line{
    width: 80px;
    height: 3px;
    background-color: #35ab61;
    border-radius: 4px;
    margin-bottom: 24px;
  }
}
.ant-btn-primary {
  height: 44px;
  box-shadow: 0px 7px 12px 0px 
		rgba(53, 171, 97, 0.33);
  margin-top: 28px;
}
</style>