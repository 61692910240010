var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('Header'),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"forgot"},[_c('h3',[_vm._v("找回密码")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"form"},[_c('a-form',{ref:"formForgot",attrs:{"id":"formForgot","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"container"},[_c('a-form-item',{attrs:{"label":"报名号/身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [{ required: true, message: '请输入报名号/身份证号' }],
                    validateTrigger: 'change',
                  },
                ]),expression:"[\n                  'username',\n                  {\n                    rules: [{ required: true, message: '请输入报名号/身份证号' }],\n                    validateTrigger: 'change',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"text","placeholder":"请输入报名号/身份证号"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [{ required: true, message: '' },{ validator: _vm.validatePhone }],
                    validateTrigger: 'change',
                  },
                ]),expression:"[\n                  'phone',\n                  {\n                    rules: [{ required: true, message: '' },{ validator: validatePhone }],\n                    validateTrigger: 'change',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"text","maxLength":11,"placeholder":"请输入手机号"}})],1),_c('a-form-item',{attrs:{"label":"验证码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code',
                  {
                    rules: [{ required: true, message: '请输入验证码' }],
                    validateTrigger: 'change',
                  },
                ]),expression:"[\n                  'code',\n                  {\n                    rules: [{ required: true, message: '请输入验证码' }],\n                    validateTrigger: 'change',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"text","placeholder":"请输入验证码"}},[_c('a-button',{staticClass:"codeBtn",attrs:{"slot":"addonAfter","type":"link","disabled":_vm.codeDisabled},on:{"click":_vm.sendCode},slot:"addonAfter"},[_vm._v(_vm._s(_vm.btnText))])],1)],1),_c('a-form-item',{attrs:{"label":"设置密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '' },{ validator: _vm.pwdValid}],
                    validateTrigger: 'blur',
                  },
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '' },{ validator: pwdValid}],\n                    validateTrigger: 'blur',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请输入密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'passwordTwo',
                  {
                    rules: [{ required: true, message: '' },{ validator: _vm.pwdEcValid }],
                    validateTrigger: 'blur',
                  },
                ]),expression:"[\n                  'passwordTwo',\n                  {\n                    rules: [{ required: true, message: '' },{ validator: pwdEcValid }],\n                    validateTrigger: 'blur',\n                  },\n                ]"}],staticClass:"cus-input",attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请再次输入密码"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"type":"primary","shape":"round","size":"large","block":"","htmlType":"submit"}},[_vm._v("确认找回")])],1)],1)])],1)])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }